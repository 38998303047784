exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-category-jsx": () => import("./../../../src/templates/article/category.jsx" /* webpackChunkName: "component---src-templates-article-category-jsx" */),
  "component---src-templates-article-lang-jsx": () => import("./../../../src/templates/article/lang.jsx" /* webpackChunkName: "component---src-templates-article-lang-jsx" */),
  "component---src-templates-article-post-jsx": () => import("./../../../src/templates/article/post.jsx" /* webpackChunkName: "component---src-templates-article-post-jsx" */),
  "component---src-templates-article-tag-jsx": () => import("./../../../src/templates/article/tag.jsx" /* webpackChunkName: "component---src-templates-article-tag-jsx" */),
  "component---src-templates-page-page-jsx": () => import("./../../../src/templates/page/page.jsx" /* webpackChunkName: "component---src-templates-page-page-jsx" */)
}

